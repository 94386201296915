import React from 'react';
import { gimmeOne } from '../utilities/utils.js';

export default function Affirmations() {
  return (
    <div style={{ width: `${600}px`, marginLeft: 'auto', marginRight: 'auto' }}>
      <h1>Repeat to yourself out loud... </h1>
      {Object.keys(affirmations).map(makeAffirmation)}
      <br />
      <p>Great job... now fill in the blank...</p>
      <p>Today I’m going to ___________, and I’m excited because ___________</p>
    </div>
  );

  function makeAffirmation(affirmationName) {
    return (
      <div key={affirmationName}>
        <h2>{affirmationName}</h2>
        <p>{gimmeOne(affirmations[affirmationName])}</p>
      </div>
    );
  }
}

var affirmations = {
  'Personalized Affirmation': [
    'I can.  I will.  End of Story.',
    'I am the captain of my ship, I am the master of my fate.',
    'I am adventerous.  I overcome my fears by following my dreams',
    'I learn new things. I focus my mind. I will make cool shit',
    "I'm in charge of how I feel, and I am choosing happiness",
    'I am my own superhero.  I can save me from the world.',
    "I'm choosing.  I'm not waiting to be chosen.",
    'I have the power to create change.',
    'I let go of all that no longer serves me.',
    "Note to self: I'm going to make you proud",
    'I possess the knowledge, skills and abilities to do anything.  If for whatever reason I do not currently posses them, I know how to aquire them.',
    'I reserve the right to change my mind whenever I like as new information becomes available',
    'I have the courage to stand up for myself.',
    "Fuck it. You miss 100% of the shots you don't take",
    'I am the captain of my ship, I am the master of my fate.',
    'Courage is what it takes to stand up and speak; courage is also what it takes to sit down and listen.',
    'Confidence flows from my belief.  And I believe I am equipped to do anything or learn how.',
    'I believe in myself. I believe in my ability as a leader.  I believe in my ability to create amazing things. If I believe in myself others will to.',
    'Confidence is an outcome of the thoughts I think and the actions I take. You do worthwhile things.  Your confidence can only grow.',
  ],
  'Self Love Affirmation': [
    'I love myself for who I am.',
    'I am relaxed and calm.',
    'I am letting go of all my worries and fears.',
    'My mind is slowing down.',
    'Being calm and centered is one of the top priorities in my life.',
    'I am practicing this feeling.',
    'I am breathing slowly and deeply, filling myself with calmness.',
    'I deserve a peaceful and loving life.',
    'I am centered and quiet.',
    'My muscles are relaxed.',
    'My thoughts are calming down.',
    'I am releasing my concerns by breathing slowly and deeply.',
    'Challenges bring opportunities.',
    'I see stressful situations as challenges.',
    'I find love and support inside and out.',
    'I am calm and relaxed in all situations.',
    'My tension is melting away.',
    'I am releasing all negative emotions from my system.',
    'Calmness washes over me with every deep breath I take.',
    'I am relaxing and feeling more and more sleepy.',
    'I am comfortable around other people.',
    'I let go of defensiveness and choose to fill my world with joy and kindness instead.',
    'I lovingly forgive and let go of the past.',
    'I am thankful and grateful for the good in my life.',
    'I give myself a credit every time I do something that makes me happy.',
  ],
  'Self Esteem Affirmation': [
    'I am aware of what I offer to the world.',
    'I am positive-minded and filled with self-esteem.',
    'My life is abundant and full of joy.',
    'I am secure and safe in the world.',
    'I focus on my success and it grows constantly.',
    'I am releasing all my fears and worries. I am living my full potential.',
    'My capability and potential are endless. I deserve to be happy and successful.',
    'I now express my full potential.',
    'I see problems as challenges that make me grow.',
    'I accept myself and know that I am worthy of great things in life.',
    'I am willing to step out of my comfort zone.',
    'I believe in myself. I have the ability to succeed.',
    'I trust myself. I am special exactly as I am.',
    'I have the knowledge and the resources to achieve my dreams.',
    'I work for the good of others.',
    'I make efficient use of my time.',
    'New ideas come to me regularly.',
    'I recognize the many good qualities that I have.',
    'I am a great person.',
    'I give myself credit every time I do something that makes me happy.',
    'I can accomplish anything I set my mind to.',
    'I have something special to offer the world.',
  ],
  'Healing Affirmation': [
    'I give myself permission to heal.',
    'I am willing to forgive myself.',
    'I am ready to forgive anyone I feel has hurt me.',
    'I let go of my perceived pain.',
    'I’m willing to see things differently.',
    'I let go of my need to be right.',
    'I accept the lesson my pain is offering me.',
    'I see my current circumstances as an opportunity to grow.',
    'I allow myself to give and receive love.',
    'I release the past and trust that everything is happening for my greatest good.',
    'I take responsibility for the quality of my relationships.',
    'I am willing to give the love I expect from others.',
    'I am happy to give without expectations.',
    'I am capable of unconditional love',
    'I treat the people in my life with compassion and understanding.',
    'I know these circumstances are a gift to help me grow.',
    'I set myself and others free by choosing to forgive.',
    'Everything I am going through is making me a stronger, wiser, and more compassionate person.',
    'I trust that everything in my life is unfolding perfectly.',
    'I create loving and healthy relationships.',
    'I am willing to be at peace with myself and everyone.',
    'I bless the past and embrace the present moment with an open heart.',
    'I choose to forgive because it feels better to love.',
    'I accept my part in everything that happens in my life.',
    'I am willing to amend my own behavior.',
    'I acknowledge my unresolved feelings with patience and self-reflection.',
    'I respect and validate the feelings of others.  ',
    'I allow the perspective of others to deepen my understanding and love for them.',
    'I am kind to myself and others.',
    'I always choose love.',
  ],
  'Joy Affirmation': [
    'I have the power to shape my ideal reality',
    'I create the life I desire with my good feelings',
    'Everything is always working out well for me',
    'When I feel happy I manifest more reasons to be happy',
    'I am willing to be happy now',
    'I accept that happiness is my true nature',
    'I am worthy of feeling happy',
    'My happiness comes from within me',
    'I create my happiness by accepting every part of myself with unconditional love',
    'Joy is the essence of my being',
    'I see so many positives in my life',
    'I am constantly creating everything my heart desires',
    'I experience joy in everything I do',
    'I feel happy with myself as a person',
    'I give myself permission to enjoy myself',
    'I allow myself to feel good',
    'The life I’ve always dreamed of is created by my choice to be joyful now',
    'Following my joy reveals the path to my best life',
    'My choice to be happy keeps me in perfect health',
    'The happiness I feel is felt by everyone around me',
    'I create the possibility of happiness for others by being happy',
    'I am meant to live a happy life',
    'My inner joy expands when I share it with others',
    'All the good in my life comes to me as result of my willingness to find happiness in each moment',
    'My happiness is reflected back to me in everything I attract.',
    'My inner joy is the source of all the good in my life',
    'I experience joy in everything I do',
  ],
  'Relationship Affirmation': [
    'I understand that every relationship starts within me. I have a wonderful loving relationship with myself.',
    'I deserve to be loved.',
    'I am thankful and grateful for the love in my life.',
    'I am ready for healthy and loving relationship.',
    'I attract positive people into my life.',
    'I am surrounded by loving, supporting, and positive people.',
    'I deserve the best in life.',
    'I am worthy of love.',
    'Love surrounds me in every way.',
    'People like me for who I am. I am special and see the specialty of others.',
    'I open myself to a good, healthy, and loving relationship in my life.',
    'I deserve happy life full of love.',
    'I visualize a new relationship with my partner and it makes me feel loved.',
    'I respect myself and my partner respects me.',
  ],
  'Food Affirmation': [
    'I have committed myself to a healthier lifestyle.',
    'I bless my food before eating it.',
    'I am so grateful now that I have healthy eating habits.',
    'I make wise food choices.',
    'Everyday is a new beginning.',
    'I am enjoying my weight release journey. This time I will complete what I started.',
    'I eat in proper portions.',
    'Eating healthy comes naturally to me.',
    'Exercising comes naturally to me.',
    'I stay focused on my ideal size.',
    'I find time to exercise.',
    'My metabolism rate is at its optimum and it helps me in reaching my ideal weight.',
    'I love myself unconditionally.',
    'Every physical movement that I make burns the extra fat in my body and helps me to maintain my ideal body weight.',
    'I easily control my weight through a combination of healthy eating and exercising.',
    'I love living in this beautiful body.',
    'I crave food that energizes me and makes me feel good.',
    'When I use my muscles I feel powerful and alive.',
    'Working out releases stress and tension, the more I move the more relief I feel.',
    'Food is my fuel, I give my body clean,healthy fuel.',
    'I feel good inside this body.',
    'This is my body, I treat it with respect and honor.',
    'Healthy nutritious food is what I crave to eat.',
    'I workout and see the results right away in my energy stamina and strength.',
    'I am powerful, unstoppable, amazing. I can achieve my desired physique.',
  ],
};
